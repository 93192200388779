import React, { useEffect, useState } from 'react';


const Footer: React.FC = (): JSX.Element => {

  return (
    <div className='d-flex main-container-full main-footer justify-content-between theme-footer' style={{ height: "40px" }}>
      <div className='main-container-content d-block'>
        <p className='mb-0 W-100 d-flex align-items-center justify-content-between'>
          <span className='theme-secondary-color fs-small'>{`Copyright @ ${new Date().getFullYear()} Ctrlcampus`}</span>
          <span className='theme-secondary-color fs-small'>Version 1.7.6</span>
          <small>This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </small>
          <style dangerouslySetInnerHTML={{
            __html: `.grecaptcha-badge { visibility: hidden;}`
          }} />
        </p>
      </div>
    </div>
  )
}

export default React.memo(Footer);